
































.container {
  display: inline-block;
  transition: transform 0.2s linear;
  &:hover {
    transform: translate(0, -20px);
    > div {
      &:nth-child(2) {
        > h4 {
          color: #000096;
        }
      }
    }
  }

  > div {
    &:nth-child(1) {
      text-align: center;

      > img {
        width: 100%;
      }
    }

    &:nth-child(2) {
      padding: 10px 14px;
      background-color: #fff;

      > span {
        font-size: 16px;
        color: #505050;
      }

      > h4 {
        font-size: 22px;
        font-weight: 500;
        color: #000;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        overflow: hidden;
      }

      > p {
        font-size: 14px;
        font-weight: 500;
        color: #505050;
        line-height: 24px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
      }
    }
  }
}
