































































































.container {
  > div:nth-child(1) {
    > ul {
      display: flex;
      border-bottom: 1px #ccc solid;

      > li {
        height: 50px;
        line-height: 50px;
        font-size: 20px;
        color: #333;
        text-align: center;
        flex: 1;
        cursor: pointer;
      }

      .current {
        color: #000096;
        border-bottom: 3px #000096 solid;
      }
    }
  }
  > div:nth-child(2) {
    display: none;
    margin-right: 30px;
    margin-left: 30px;
    margin-bottom: 15px;
    >.proCategory {
      display: block;
      width: 100%;
      height: 34px;
      padding: 6px 12px;
      font-size: 14px;
      line-height: 1.42857143;
      color: #555;
      background-color: #fff;
      background-image: none;
      border: 1px solid #ccc;
      border-radius: 4px;
    }
  }
}
@media (max-width: 767px) {
  .container{
    > div:nth-child(1) {
      display: none;
    }
    > div:nth-child(2) {
      display: block;
    }
  }
}
