





















































































































































































































section {
  background-color: #F8F8F8;
  padding-bottom: 110px;
  main {
      width: 1200px ;
      margin: 110px auto 0;
    > div {

      &:nth-child(1) {
        margin-bottom: 80px;
        display: flex;
        justify-content: space-between;
        width: 100%;

        > div {
          &:nth-child(1) {
            width: 600px;
            overflow: hidden;

            > ul {
              display: flex;
              transition: transform 0.5s linear;
            }
          }

          &:nth-child(2) {
            padding: 30px;
            background: #fff;
            flex: 1;

            > div:nth-child(1) {
              > span:nth-child(1) {
                font-size: 30px;
                color: #000096;
              }

              > span:nth-child(2) {
                font-size: 16px;
                color: #333;
              }
            }

            > h2 {
              font-size: 26px;
              color: #000;
              margin-top: 20px;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 1;
              overflow: hidden;
            }

            > p {
              font-size: 14px;
              color: #333;
              font-weight: 500;
              line-height: 30px;
              padding: 0 30px;
              margin-top: 50px;
              overflow: hidden;
              position: relative;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 3;

              &::before {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 17px;
                height: 15px;
                background: url("../assets/img/icon-syh.png") no-repeat center;
              }

              &::after {
                content: "";
                position: absolute;
                bottom: 0;
                right: 0;
                width: 17px;
                height: 15px;
                background: url("../assets/img/icon-syh.png") no-repeat center;
                transform: rotate(180deg);
              }
            }

            > div:nth-child(4) {
              margin-top: 50px;
              display: flex;

              > i {
                display: inline-block;
                width: 10px;
                height: 5px;
                margin: 0 4px;
                background-color: #646464;
              }

              .current {
                width: 25px;
                background-color: #000096;
              }
            }
          }
        }
      }
    }

    .tab-list {
      margin: 50px 0;
      margin-right: -30px;

      > ul {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;

        > li {
          width: 380px;
          margin-right: 30px;
          margin-bottom: 20px;
        }
      }
    }

    .pagination {
      > .container {
        justify-content: center;
      }
    }
  }
}
@media (max-width: 767px) {
  section {
    main {
      width: 100%;
      >div:nth-child(1){
        display: none;
      }
      >div:nth-child(2){
        margin-bottom: 30px;
      }
      .tab-list{
        margin: 0;
        >ul{
          display: block;
          >li{
            width: 100%;
            margin: 0 auto;
            max-width: 490px;
          }
        }
      }
    }
  }
}
