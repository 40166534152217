











































































.container {
  display: flex;

  .current {
    background-color: #B4B4B4;
  }

  > div {
    margin: 0 2px;
    width: 45px;
    height: 40px;
    line-height: 40px;
    color: #000;
    font-size: 14px;
    border: 1px #ccc solid;
    cursor: pointer;
    text-align: center;

    &:nth-child(1) {
      background: url("../assets/img/arrow-left-head.png") no-repeat center;
    }

    &:nth-child(2) {
      background: url("../assets/img/arrow-left.png") no-repeat center;
    }

    &:nth-last-child(1) {
      background: url("../assets/img/arrow-left-head.png") no-repeat center;
      transform: rotate(180deg);
    }

    &:nth-last-child(2) {
      background: url("../assets/img/arrow-left.png") no-repeat center;
      transform: rotate(180deg);
    }

    &:hover {
      background-color: #B4B4B4;
    }
  }
}
